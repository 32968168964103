import thumbnailUlaanbaatar from '@/assets/img/regions/ulaanbaatar.jpg';
import thumbnailCentral from '@/assets/img/regions/central.jpg';
import thumbnailWestern from '@/assets/img/regions/western.jpg';
import thumbnailEastern from '@/assets/img/regions/eastern.jpg';
import thumbnailGobi from '@/assets/img/regions/gobi.jpg';
import thumbnailKhangai from '@/assets/img/regions/khangai.jpg';

export default {
  "categories": [{
    "id": "do",
    "title": "Do"
  },
  {
    "id": "shop",
    "title": "Shop"
  },
  {
    "id": "taste",
    "title": "Taste"
  },
  {
    "id": "move",
    "title": "Move"
  },
  {
    "id": "stay",
    "title": "Stay"
  },
  {
    "id": "others",
    "title": "Others"
  }
  ],
  "travelerTypes": [{
    "id": "adventure",
    "title": "Adventure",
    "color": "#340A4E"
  },
  {
    "id": "taste",
    "title": "Taste",
    "color": "#5F120F"
  },
  {
    "id": "history",
    "title": "History & Culture",
    "color": "#10523B"
  },
  {
    "id": "nature",
    "title": "Nature",
    "color": "#D57504"
  },
  {
    "id": "buddhist-tourism",
    "title": "Buddhist Tourism",
    "color": "#D59D04"
  },
  {
    "id": "family",
    "title": "Family",
    "color": "#34679F"
  },
  {
    "id": "community",
    "title": "Community Based",
    "color": "#911457"
  },
  {
    "id": "mice",
    "title": "MICE",
    "color": "#222F6A"
  }
  ],
  "travelThemes": {
    "festivals": {
      "id": 178,
      "name": "Festivals"
    },
    "nature": {
      "id": 179,
      "name": "Nature"
    },
    "family-activities": {
      "id": 180,
      "name": "Family Activities"
    },
    "buddhist-tourism": {
      "id": 181,
      "name": "Buddhism Tourism"
    },
    "history": {
      "id": 182,
      "name": "History and Culture"
    },
    "bird-watching": {
      "id": 183,
      "name": "Bird Watching"
    },
    "community-based": {
      "id": 184,
      "name": "Community Based Tourism"
    },
    "taste": {
      "id": 185,
      "name": "Taste of Mongolia"
    },
    "adventure": {
      "id": 186,
      "name": "Adventure"
    },
    "mice": {
      "id": 187,
      "name": "MICE"
    }
  },
  "locations": [{
    "id": "5c7cecc92f67292128940e77",
    "name": "mongolia-ulaanbaatar",
    "title": "Ulaanbaatar & Surrounding",
    "shortTitle": "Ulaanbaatar",
    "subtitle": "The capital city of Mongolia",
    "alias": "ulaanbaatar",
    "description": "Ulaanbaatar is the capital of Mongolia. It’s in the Tuul River valley, bordering the Bogd Khan Uul National Park. Originally a nomadic Buddhist center, it became a permanent site in the 18th century. Soviet control in the 20th century led to a religious purge. Soviet-era buildings, museums within surviving monasteries, and a vibrant conjunction of traditional and 21st-century lifestyles typify the modern city.",
    header: thumbnailUlaanbaatar
  }, {
    "id": "5c7cece02f67292128941a6e",
    "name": "mongolia-central",
    "title": "Central Region",
    "subtitle": "Central and Northern of Mongolia",
    "alias": "central",
    "description": "If you are looking for culture and interacting with ethnic minority groups, the centralregion of Mongolia is the place to go. There is a curious mix of cultures and landscapes of mountains and arid plains.Some popular activities here are fishing, hiking,and backcountry trips through forests, mountains, lakes and rivers. Khovsgol Nuur,the biggestfreshwater lake and the largestriverof Mongoliaare also found in this region.",
    header: thumbnailCentral
  }, {
    "id": "5c7cecf22f672921289422d3",
    "name": "mongolia-western",
    "title": "Western Region",
    "subtitle": "Western of Mongolia",
    "alias": "western",
    "description": "Western Mongolia has a lot to offer for adventurous travelers. Spectacular landscapes and sceneries, historic sites, and challenging treks are just some of the reasons this region is worth the visit. Bayan-Ulgii in particular is one of the highlights here as it is dubbed as the ‘roof of the world’ with the tallest mountain -Khuiten Uul at4,374m. ",
    header: thumbnailWestern
  }, {
    "id": "5c7ced092f67292128942f35",
    "name": "mongolia-eastern",
    "title": "Eastern Region",
    "subtitle": "Eastern of Mongolia",
    "alias": "eastern",
    "description": "Eastern Mongolia is the least visited region of the country. For explorers, who like destinations that areoff-the-beaten-path, the east is aparadise. Travelers looking for adventures in the great outdoors filled with wildlife, historic sites, and horse riding should consider visiting this part of Mongolia. It’s also where Genghis Khan was born (Khentii Aimag) where visitors can see relics andsites related to his life.",
    header: thumbnailEastern
  }, {
    "id": "5c7ced332f67292128944611",
    "name": "mongolia-gobi",
    "title": "Gobi",
    "subtitle": "Gobi Desert of Mongolia",
    "alias": "gobi",
    "description": "One of, if not, the most popular destinations in Mongolia is the Gobi desert. It is the epitome of iconic adventures where travelers can go sand dune trekking and camel riding or be a paleontologist for a day by searching for dinosaur eggs and fossils.",
    header: thumbnailGobi
  }, {
    "id": "5c7ced242f67292128943e56",
    "name": "mongolia-khangai",
    "title": "Khangai",
    "subtitle": "Khangai Mountain",
    "alias": "khangai",
    "description": "The Khangai Region, home to the glorious Arkhangai mountain range,is one of the most popular areas for visitors with its lush forest-steppes. It is within easy reach ofUlaanbaataand there are plenty of historic sites, and adventures to be had here. Experience the vast Mongolian nature, horse-back riding, and nomadic living in a Ger in a local community.",
    header: thumbnailKhangai
  }]
}