import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import KsVueScrollmagic from "ks-vue-scrollmagic";
import VueCookies from "vue-cookies";
import VueTruncate from "vue-truncate-filter";
import VueMoment from "vue-moment";
import VueToasted from "vue-toasted";

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "./custom.scss";

import "ew-standard-page/dist/style.css";
import EWStandardPage from "ew-standard-page";
EWStandardPage.init();

Vue.config.ignoredElements = ["exp-wc"];

Vue.use(VueMoment);
Vue.use(BootstrapVue);
Vue.use(KsVueScrollmagic);
Vue.use(VueCookies);
Vue.use(VueTruncate);
Vue.use(VueToasted);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
