import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/Home.vue"),
      meta: {
        title: "Mongolia"
      }
    },
    {
      path: "/experience/:name",
      name: "experience",
      component: () => import("../views/Experience.vue")
    },
    {
      path: "/festivals-events",
      name: "festivals-events",
      component: () => import("../views/FestivalsEvents.vue"),
      meta: {
        title: "Mongolia"
      }
    },
    {
      path: "/first-time-traveler",
      name: "first-time-traveler",
      component: () => import("../views/FirstTimeTraveler.vue"),
      meta: {
        title: "Mongolia"
      }
    },
    {
      path: "/comnunity-based-tourism",
      name: "comnunity-based-tourism",
      component: () => import("../views/FestivalsEvents.vue"),
      meta: {
        title: "Mongolia"
      }
    },
    {
      path: "/map",
      name: "map",
      component: () => import("../views/Map.vue"),
      meta: {
        title: "Map"
      }
    },
    {
      path: "/traveler-snapshots",
      name: "traveler-snapshots",
      component: () => import("../views/TravelerSnapshots.vue"),
      meta: {
        title: "Traveler Snapshots"
      }
    },
    {
      path: "/event-calendar",
      name: "event calendar",
      component: () => import("../views/EventCalendar.vue"),
      meta: {
        title: "Event Calendar"
      }
    },
    {
      path: "/offers",
      name: "offers",
      component: () => import("../views/Offers.vue"),
      meta: {
        title: "Offers"
      }
    },
    {
      path: "/themes/:nametravel",
      name: "themes",
      component: () => import("../views/Themes.vue"),
      meta: {
        title: "Themes"
      }
    },
    {
      path: "/plan",
      name: "plan",
      component: () => import("../views/PlanYourTrip/Layout"),
      children: [
        {
          path: "",
          name: "getting-to-mongolia",
          component: () => import("../views/PlanYourTrip/Getting"),
          meta: {
            title: "Getting to Mongolia"
          }
        },
        {
          path: "air-transport",
          name: "air-transport",
          component: () => import("../views/PlanYourTrip/AirTransport"),
          meta: {
            title: "Air Transport"
          }
        },
        {
          path: "ground-transport",
          name: "ground-transport",
          component: () => import("../views/PlanYourTrip/GroundTransport"),
          meta: {
            title: "Ground Transport"
          }
        },
        {
          path: "travel-around",
          name: "travel-around",
          component: () => import("../views/PlanYourTrip/TravelAround"),
          meta: {
            title: "Travel Around"
          }
        },
        {
          path: "custom-culture",
          name: "custom-culture",
          component: () => import("../views/PlanYourTrip/CustomCulture"),
          meta: {
            title: "Custom & Culture"
          }
        },
        {
          path: "practical-information",
          name: "practical-information",
          component: () => import("../views/PlanYourTrip/PracticalInformation"),
          meta: {
            title: "Practical Information"
          }
        }
      ]
    },
    {
      path: "/regions",
      name: "regions",
      component: () => import("../views/Regions/Layout"),
      children: [
        {
          path: ":name",
          name: "region-detail",
          component: () => import("../views/Regions/Detail"),
          meta: {
            title: "Region"
          }
        }
      ]
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("../views/Privacy.vue"),
      meta: {
        title: "Privacy"
      }
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import("../views/Terms.vue"),
      meta: {
        title: "Terms of Use"
      }
    },
    {
      path: "/cookies",
      name: "cookies",
      component: () => import("../views/Cookies.vue"),
      meta: {
        title: "Cookies"
      }
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("../views/Contact.vue"),
      meta: {
        title: "Contact Us"
      }
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("../views/ResetPassword.vue"),
      meta: {
        title: "Reset Password"
      }
    },
    {
      path: "/404",
      name: "404",
      component: () => import("../views/404.vue"),
      meta: {
        title: "Page Not Found"
      }
    },
    {
      path: "/feelmongolia",
      name: "feelmongolia",
      component: () => import("../views/Campaigns/Layout"),
      children: [
        {
          path: "",
          name: "campaign-home",
          component: () => import("../views/Campaigns/CampaignHome"),
          meta: {
            title: "Campaign Home"
          }
        },
        {
          path: "partners",
          name: "Partners",
          component: () => import("../views/Campaigns/Partners"),
          meta: {
            title: "Partners"
          }
        },
        {
          path: "prizes",
          name: "Prizes",
          component: () => import("../views/Campaigns/Prizes"),
          meta: {
            title: "Prizes"
          }
        },
        {
          path: "terms-conditions",
          name: "Terms & Conditions",
          component: () => import("../views/Campaigns/TermsConditions"),
          meta: {
            title: "Terms & Conditions"
          }
        },
        {
          path: "contact",
          name: "Contact",
          component: () => import("../views/Campaigns/Contact"),
          meta: {
            title: "Contact"
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    }
  ]
});
