import Vue from 'vue'
import Vuex from 'vuex'

import ref from './data/ref';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ref, // For referencing across the web.
  },
  mutations: {

  },
  actions: {

  }
})
