<template>
    <div class="mobile">
        <div class="box-logo">
            <img :src="logo" alt="logo" class="logo">
        </div>
        
        <div class="popup">
            <img :src="logoPopup" alt="logoPopup" class="logoPopup mb-3">
            <p>Welcome to Mongolia.travel!</p>
            <p>We are currently developing a new mobile website and are looking forward to providing you with inspiring new content about experiences in Mongolia shortly.</p>
        </div>
    </div>
</template>

<script>
import logo from '../assets/img/logo-mongolia-home.png'
import logoPopup from '../assets/img/logo-popup.png';
export default {
    name : "mobile",
    data() {
        return {
            logo : logo,
            logoPopup : logoPopup
        }
    }
}
</script>

<style lang="scss" scoped>
    .mobile {
        padding-bottom: 50px;
        background-image: url('../assets/img/bg-mongolia.jpg');
        background-size: cover;
		background-position: center;
        background-attachment: fixed;
		height: auto;
        
        .box-logo {
            padding-top: 10px;
            padding-bottom: 30px;
            background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,0));
            text-align: center;
        }
        

        .popup {
            margin: auto;
            width:75%;
            padding: 20px;
            border: 6px #00336E solid;
            border-radius: 6px;
            background-color: #FFF;
            color: #00336E;
            .logoPopup {
                width: 100%;
            }
        }
    }
</style>


